<template>
  <v-card data-app>
    <v-card-title>
      Donations
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-col class="text-center" cols="12" sm="6" lg="1">
        <download-excel
          class="btn btn-default"
          :data="json_data"
          :fields="json_fields"
          worksheet="My Worksheet"
          name="donations.xls"
        >
          Export
        </download-excel>
      </v-col>
    </v-card-title>
    <v-data-table
      :items="posts.donationDetails"
      :headers="headers"
      :search="search"
    >
      <template #item.donation_id="{value}">
        <router-link :to="`/donationView/${value}`"> {{ value }} </router-link>
      </template>
      <template #item.amount="{value}">
        <span>{{ value | currency }} </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);
Vue.filter("currency", function(value) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0
  });
  return formatter.format(value);
});

export default {
  name: "donations",
  data() {
    return {
      posts: [],
      errors: [],
      search: "",

      headers: [
        {
          text: "",
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: "Id",
          value: "donation_id"
        },
        {
          text: "Project Name",
          value: "project_name"
        },
        {
          text: "Donor Name",
          value: "donor_name"
        },
        {
          text: "PAN",
          value: "pan_no"
        },
        {
          text: "Payment Date",
          value: "created_at"
        },
        {
          text: "Amount",
          value: "amount"
        },
        {
          text: "Status",
          value: "status"
        },
      ],

      json_fields: {
        Id: "donation_id",
        "Project Name": "project_name",
        "Payment Date": "created_at",
        Amount: "amount",
        Status: "status",
        "Referred By": "referred_by",
        "Donor Id": "donorId",
        "Donor Name": "donor_name",
        PAN : "pan_no",
        Phone : "phone",
        Email : "email",
        "Indian Passport Holder": "indian_passport_holder",
        Gender: "gender",
        DOB: "dob",
        Address: "address",
        City: "city",
        District: "district",
        State: "state",
        "Postal Code": "postal_code",
        Country: "country",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  // Fetches posts when the component is created.
  created() {
    this.fetchDonations();
  },
  methods: {
    fetchDonations() {
      ApiService.setHeader();
      ApiService.get("/donationview/").then(response => {
        // JSON responses are automatically parsed.
        this.posts = response.data;
        this.json_data = this.posts.donationDetails;
      });
    }
  }
};
</script>
